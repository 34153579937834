<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Create Permission</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active">
                    <router-link :to="{name: 'permissions'}">
                        <span>Permissions</span>
                    </router-link>
                </li>
                <li class="breadcrumb-item active">Create</li>
            </ol>
        </nav>
        <br />
        <div class="container-fluid">
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div class="col-md-8">
                <form>
                    <div class="form-group row">
                        <label class="col-md-3">
                            Permission
                            <span class="text-danger" style="font-weight:bold;">*</span>
                        </label>
                        <div class="col-md-9">
                            <ValidationProvider
                                name="permission"
                                rules="required|min:2"
                                v-slot="{ errors }"
                            >
                                <input
                                    type="text"
                                    class="form-control"
                                    aria-describedby="permission1HelpInline"
                                    v-model="permission.name"
                                    autocomplete="off"
                                    v-on:keydown.enter="createPermission($event)"
                                />
                                <small
                                    v-if="errors.length == 0"
                                    id="permission1HelpInline"
                                    class="text-muted field"
                                >The given permission should contain Capital letters and Underscores only</small>
                                <small
                                    v-else
                                    id="permission1HelpInline"
                                    class="text-danger field"
                                >{{ errors[0] }}</small>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="form-group row" style="float:right;">
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                class="btn btn-primary text-right ma-10px"
                                v-on:click="createPermission()"
                            >Submit</button>
                        </div>
                        <div class="offset-xs-3 col-xs-9">
                            <button
                                type="button"
                                @click="$router.go(-1)"
                                class="btn btn-secondary ma-10px"
                            >Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            permission: {
                name: ""
            },
            api_error: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10
        };
    },
    created() {
        this.access = this.hasPermission("CAN_CREATE_PERMISSION");
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        createPermission: function() {
            this.startProgressBar();
            const data = {
                name: this.permission.name
            };
            this.axios
                .post(this.$api.create_permissions, data, this.getAuthHeaders())
                .then(() => {
                    this.updateProgressBar(true);
                    this.$router.push({ name: "permissions" });
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                    window.scrollTo(0, 0);
                });
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>
